/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CollaboratingGroupContext, CrfCondition, CrfForm,
    Field, FieldGroup, FieldProvider, FormBreadcrumbs, FormGrid, FormOptionsContext, GetFieldLookupItem, ICrfConditionParameters, IFormGridCell, IFormGridRemoveDialogProps, InstitutionContext, KeyboardDatePicker, MasterGroupContext, Numeric, OnlinePatientManagementContext, PatientContext,
    ProgressButton,
    RouteLoading, Select, SubmitButton, Text, useFieldState, useFormActions, useFormState, useSnackbar
} from '@ngt/opms';

import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, makeStyles, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';

import { cloneDeep, get } from 'lodash-es';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faDownload } from '@fortawesome/pro-duotone-svg-icons/faDownload';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import { DateTime } from 'luxon';
import * as Dtos from '../../api/dtos';
import InputDisplay from '../../components/InputDisplay';
import SourceDocuments from 'src/components/SourceDocuments';
import { useHistory } from 'react-router-dom';
import { AlertTitle } from '@material-ui/lab';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface ITissueSampleSubmissionProps {
}

interface ISampleContext {
    index: number | undefined;
    setIndex: (index: number | undefined) => void;
    openSampleDialog: boolean;
    setOpenSampleDialog: (open: boolean) => void;
}

interface ITissueSampleGridProps {
    prosignaAssayResultStatus: number | undefined;
    prosignaAssayResultStatusLoading: boolean;
    updateProsignaResultStatus: (status?: number) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 2)
    },
    dialogFormTitle: {
        color: theme.palette.secondary.main,
        borderBottom: 'solid rgb(224, 224, 224) 1px',

        '& *': {
            fontSize: '1.5rem !important'
        }
    },
    dialogButton: {
        textAlign: 'right',
        padding: theme.spacing(2),

        '& > :first-child': {
            marginRight: theme.spacing(1),
        }
    },
    buttonGroup: {
        padding: theme.spacing(0, 3, 3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    },
    insufficientTissue: {
        fontSize: '1.25rem',
        display: 'flex',
        margin: '1rem 8rem',
        textAlign: 'center',
        flexDirection: 'column'
    },
    insufficientTissueBtnGroup: {
        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        padding: '1rem'
    },
    downloadIcon: {
        minWidth: 0,
        minHeight: 32,

        '& > *': {
            opacity: 0.9
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */


const isSampleShipped = ({ formState, parentFieldName }: ICrfConditionParameters<Dtos.TissueSampleSubmission>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.TissueSample = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.shipmentStatus === Dtos.LookupShipmentStatusType.Shipped;
};

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsCrfUpdate
];

const GenerateButton: React.FunctionComponent = () => {

    const { value } = useFieldState({ value: true });

    const { patient } = React.useContext(PatientContext);

    const classes = useStyles();

    const onClick = React.useCallback((e) => {
        e.stopPropagation();
    }, []);

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    return (
        <>
            <LightTooltip title={'Download Requisition Form'}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.downloadIcon}
                    component="a"
                    href={`/pathology-requisition-form?PatientId=${patient?.id}&TissueSampleId=${value}`}
                    onClick={onClick}
                >
                    <FontAwesomeIcon icon={faDownload} fixedWidth />
                </Button>
            </LightTooltip>
            
        </>
    );
}

const tissueSampleColumns: Array<IFormGridCell<Dtos.TissueSample>> = [
    {
        name: 'sampleNumber',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <InputDisplay />
        )
    },
    {
        name: 'sampleType',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <InputDisplay
                convert={(fieldValue: any, lookups) => GetFieldLookupItem(lookups, `tissueSamples.sampleType`, fieldValue)?.value}
            />
        )
    },
    {
        name: 'dateCollected',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <InputDisplay
                convert={(fieldValue: any) => fieldValue ? DateTime.fromISO(fieldValue).toFormat('dd/MM/yyyy') : null}
            />
        )
    },
    {
        name: 'dateSlidesWereCut',
        hidden: true
    },
    {
        name: 'tumourSampleId',
        minWidth: 160,
        maxWidth: 160,
        width: 160,
        content: (
            <InputDisplay />
        )
    },
    {
        name: 'slideIdStart',
        minWidth: 100,
        maxWidth: 100,
        width: 100,
        content: (
            <InputDisplay />
        )
    },
    {
        name: 'slideIdEnd',
        minWidth: 100,
        maxWidth: 100,
        width: 100,
        content: (
            <InputDisplay />
        )
    },
    {
        name: 'id',
        header: 'Requisition Form',
        minWidth: 50,
        maxWidth: 50,
        width: 50,
        content: (
            <GenerateButton />
        )
    },
    {
        name: 'tumourTissueOrigin',
        hidden: true
    },
    {
        name: 'invasiveTumourSize',
        hidden: true
    },
    {
        name: 'numberOfInvolvedNodes',
        hidden: true
    },
    {
        name: 'shipmentStatus',
        hidden: true
    },
    {
        name: 'trackingId',
        hidden: true
    },
    {
        name: 'dateShipped',
        hidden: true
    },
    {
        name: 'histopathologyReports',
        hidden: true
    }
];

const SampleContext = React.createContext<ISampleContext>({
    setIndex: () => { },
    index: undefined,
    openSampleDialog: false,
    setOpenSampleDialog: () => { }
});

export const RemoveDialog: React.FC<IFormGridRemoveDialogProps> = ({
    removeIndex,
    setRemoveIndex,
    rowLabel,
    classes,
    rowValue,
    removeRow
}) => {
    const [loading, setLoading] = React.useState(false);

    const { setFieldValue, submit } = useFormActions();

    const onClick = React.useCallback(async (e) => {
        setLoading(true);
        setFieldValue('submitType', 'save');
        removeRow(removeIndex);

        try {
            await submit();

            setLoading(false);
            setRemoveIndex(undefined);
        }
        catch (e) {
            setLoading(false);
            setRemoveIndex(undefined);
        }
    }, [removeIndex, removeRow, setLoading, setRemoveIndex, submit, setFieldValue]);


    console.log(rowLabel);
    console.log(rowValue);

    return (
        <Dialog open={removeIndex !== undefined && removeIndex !== null} onClose={() => setRemoveIndex(undefined)} aria-labelledby="remove-dialog" maxWidth="sm">
            <DialogTitle className={classes.dialogTitle}>
                Remove {rowLabel}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to remove {rowValue ?? 'this ' + rowLabel.toLowerCase()}?
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <ProgressButton
                    color="secondary"
                    onClick={() => setRemoveIndex(undefined)}
                    disabled={loading}
                    loading={loading}
                >
                    Cancel
                </ProgressButton>
                <ProgressButton
                    color="primary"
                    onClick={onClick}
                    disabled={loading}
                    loading={loading}
                >
                    Remove
                </ProgressButton>
            </DialogActions>
        </Dialog>
    )
}

const TissueSampleGrid: React.FunctionComponent<ITissueSampleGridProps> = ({
    prosignaAssayResultStatus,
    updateProsignaResultStatus,
    prosignaAssayResultStatusLoading
}) => {
    const classes = useStyles();

    const { getFieldValue, setFieldValue, submit } = useFormActions();

    const { errors } = useFormState();

    const [previousValue, setPreviousValue] = React.useState<Dtos.TissueSample | undefined>(undefined);

    const { index, setIndex, openSampleDialog, setOpenSampleDialog } = React.useContext(SampleContext);

    const { patient } = React.useContext(PatientContext);

    React.useEffect(() => {
        const v = getFieldValue(`tissueSamples[${index}]`);

        setPreviousValue(!v ? undefined : cloneDeep(v));
    }, [index, setPreviousValue, getFieldValue]);

    const onRowClick = React.useCallback((index: number) => {
        setPreviousValue(undefined);
        setIndex(index);
        setOpenSampleDialog(true);
    }, [setIndex, setPreviousValue, setOpenSampleDialog]);

    const onCancel = React.useCallback(() => {
        if (previousValue?.id == null) {
            let samples = getFieldValue(`tissueSamples`) as Dtos.TissueSample[];

            samples.splice(index!!, 1);

            setFieldValue(`tissueSamples`, samples);
        }
        else {
            setFieldValue(`tissueSamples[${index}]`, previousValue);
        }

        
        setPreviousValue(undefined);
        setIndex(undefined);
        setOpenSampleDialog(false);
    }, [previousValue, getFieldValue, setFieldValue, index, setIndex, setPreviousValue, setOpenSampleDialog]);

    const onSave = React.useCallback(() => {
        setFieldValue('submitType', 'save');

        submit()
            .then(_response => {
                // dialog should remain open when there is any error in the current sample for central lab (form not saved)
                let criticalError = false;

                Object.keys(errors).forEach(key => {
                    if (!criticalError) {
                        criticalError = errors[key].find((e: { type: Dtos.ValidationErrorType; }) => e.type > Dtos.ValidationErrorType.Normal) != null
                    }
                });

                if (!criticalError) {
                    setOpenSampleDialog(false);
                    setPreviousValue(undefined);
                    setIndex(undefined);
                }
            })
            .catch((e) => {

            });
    }, [setIndex, setPreviousValue, setFieldValue, setOpenSampleDialog, errors, submit]);

    //const sample = React.useMemo(() => {
    //    const v = getFieldValue<Dtos.TissueSample>(`tissueSamples[${index}]`);

    //    return v;

    //}, [index, getFieldValue]);

    const { readOnly } = React.useContext(FormOptionsContext);

    const history = useHistory();

    const { institution } = React.useContext(InstitutionContext);

    const onFormCancel = React.useCallback(() => {
        if (patient) {
            history.push(`/registration/${institution?.code}/${patient?.studyNumber}`)
        }
        else if (institution?.code) {
            history.push(`/registration/${institution?.code}`)
        }
        else {
            history.push(`/registration`);
        }
    }, [history, institution, patient]);

    const onAdd = React.useCallback((values: Dtos.TissueSample[]) => {
        const gridSize = values?.length ?? 0;

        let value = new Dtos.TissueSample({ sampleNumber: (gridSize + 1), shipmentStatus: Dtos.LookupShipmentStatusType.Pending });

        if (values) {
            return [...values, value];
        }

        return [value];
    }, []);

    const onAddClick = React.useCallback(() => {
        let samples = getFieldValue<Dtos.TissueSample[]>(`tissueSamples`);

        if (onAdd) {
            setFieldValue(`tissueSamples`, onAdd(samples));
        } else if (samples) {
            setFieldValue(`tissueSamples`, [...samples, new Dtos.TissueSample({})]);
        } else {
            setFieldValue(`tissueSamples`, [new Dtos.TissueSample({})]);
        }

        if (onRowClick) {
            onRowClick(samples?.length ?? 0);
        }

    }, [onAdd, onRowClick, getFieldValue, setFieldValue]);

    return(
        <>
            <div
            style={{
                paddingLeft: '16px',
                paddingRight: '16px'
            }}
            >
                <Typography
                    variant='body2'
                >
                    <b>Tissue slides are required to be sent for the post-randomisation Prosigna testing</b> (refer to Pathology Manual Section 4 for details). FFPE block is only to be sent at BCT request for those participants who consents to Optional Tissue Banking for Future Unspecified Research. BCT will contact the site when the samples need to be batch shipped.
                </Typography>

            </div>
            <Typography
                variant="h2"
                color="primary"
                className={classes.subHeading}
            >
                Tissue Samples
            </Typography>
            <FormGrid
                type={Dtos.TissueSample}
                name="tissueSamples"
                columns={tissueSampleColumns}
                onRowClick={onRowClick}
                onDelete={(values, index) => {
                    if (values === undefined || values === null) {
                        return;
                    }

                    if (index >= values?.length) {
                        return;
                    }

                    values = values?.filter((v, i) => index !== i);

                    values.forEach((value, index) => {
                        return value.sampleNumber = (index + 1);
                    });

                    setFieldValue('tissueSamples', values);
                    setFieldValue('submitType', 'save');

                    return values;
                }}
                rowLabel="Sample"
                getRowValue={(getFieldValue: (path: string) => string, lookups, parentName: string, index?: number) => {
                    if (parentName && index !== undefined && lookups) {
                        var rowValue = getFieldValue(`${parentName}[${index}].sampleNumber`);

                        if (rowValue) {
                            return `Sample Number ${rowValue}`;
                        }

                        return undefined;
                    }

                    return undefined;
                }}
                allowDelete={(values, index) => {
                    if (values != null) {
                        return values[index]?.shipmentStatus !== Dtos.LookupShipmentStatusType.Shipped
                    }

                    return false;
                }}
                allowAdd={false}
                RemoveDialogComponent={RemoveDialog}
            />

            {
                (prosignaAssayResultStatus === Dtos.ProsignaAssayResultStatusType.SampleNotSuitable as number || prosignaAssayResultStatus === Dtos.ProsignaAssayResultStatusType.Unsuccessful as number) && <div className={classes.insufficientTissue}>
                    There is insufficient/unsuitable tissue to complete the Prosigna Assay. Is there more tissue available that can be submitted to perform the Assay?
                    <div
                        className={classes.insufficientTissueBtnGroup}
                    >
                        <ProgressButton
                            variant="contained"
                            color="primary"
                            onClick={onAddClick}
                            loading={prosignaAssayResultStatusLoading}
                        >
                            Yes
                        </ProgressButton>
                        <ProgressButton
                            variant="contained"
                            color="primary"
                            onClick={() => updateProsignaResultStatus(Dtos.ProsignaAssayResultStatusType.NoTissueAvailable as number)}
                            loading={prosignaAssayResultStatusLoading}
                        >
                            No
                        </ProgressButton>
                    </div>
                </div>
            }

            <div
                className={classes.buttonGroup}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onFormCancel}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    type="button"
                    color="primary"
                    onClick={onAddClick}
                >
                    Add Sample
                </Button>
            </div>

            <Dialog
                open={openSampleDialog && index != null}
                onClose={() => setOpenSampleDialog(false)}
                aria-labelledby="form-dialog"
                maxWidth="md"
                scroll="body"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle className={classes.dialogFormTitle}>Tumour Sample</DialogTitle>
                <DialogContent style={{ padding: '0' }}>
                    <FieldProvider name={`tissueSamples[${index}]`}>
                        <Field
                            name="sampleNumber"
                            component={Numeric}
                            disabled
                        />
                        <Field
                            name="sampleType"
                            component={Select}
                            label="
                                Sample Type
                                <br/>
                                <div style='width: 85%'><small><i>
                                    Pre-treatment core biopsy if the patient had pre-surgical endocrine therapy, submit the sample from the core biopsy <br/>
                                    Or <br/>
                                    Surgical sample if the patient did not have pre-surgical endocrine therapy, send a sample from the main excision
                                </i></small></div>
                            "
                        />
                        <Field
                            name="dateCollected"
                            component={KeyboardDatePicker}
                        />
                            <Field
                                name="dateSlidesWereCut"
                                component={KeyboardDatePicker}
                            />
                        <Field
                            name="tumourSampleId"
                            component={Text}
                        />
                            <Field
                                name="slideIdStart"
                                component={Text}
                                label="
                                    Slide ID (start)
                                    <br/>
                                    <div style='width: 85%'><small><i>
                                        Slide sections must be sequentially numbered, starting with H&E slide as ‘1’, followed by the unstained slides as ‘2’, ‘3’, ‘4’ and so on. 
                                        There must be a minimum of 9 slides submitted, including the H&E slide.
                                    </i></small></div>
                                "
                            />

                            <Field
                                name="slideIdEnd"
                                component={Text}
                            />
                        <Field
                            name="tumourTissueOrigin"
                            component={Select}
                        />
                        <Field
                            name="invasiveTumourSize"
                            component={Numeric}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color="textSecondary">mm&nbsp;</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Field
                            name="numberOfInvolvedNodes"
                            component={Numeric}
                        />

                        <FieldGroup>
                            <Field
                                name="histopathologyReports"
                                component={Select}
                                label="
                                    <div style='width: 85%'>
                                        <small>
                                            <i>
                                                Please be reminded that histopathology reports for all surgeries, including core biopsy, main surgery, re-excision and any further axillary clearance are required to be uploaded.
                                            </i>
                                        </small>
                                    </div>
                                    Have reports from all surgeries been uploaded, including axillary clearance (if performed)?
                                    <div style='width: 85%'>
                                        <small>
                                            <i>
                                                Note: the number of involved nodes across all surgeries is critical to the Prosigna assay and incomplete/erroneous data could impact the assay result and treatment allocation.
                                            </i>
                                        </small>
                                    </div>
                                "
                            />

                        </FieldGroup>

                        <FieldGroup>
                            <Typography
                                variant="h2"
                                color="primary"
                                className={classes.subHeading}
                            >
                                Shipment Tracking
                            </Typography>
                            <Field
                                name="shipmentStatus"
                                component={Select}
                                nullOption={false}
                            />

                            <CrfCondition
                                type={Dtos.TissueSampleSubmission}
                                condition={isSampleShipped}
                                mode="Enable"
                                subscription={{ values: true }}
                            >
                                <Field
                                    name="trackingId"
                                    component={Text}
                                />
                                <Field
                                    name="dateShipped"
                                    component={KeyboardDatePicker}
                                />
                            </CrfCondition>
                        </FieldGroup>

                        <SourceDocuments
                            instructions="Please upload histopathology report for tumour sample"
                        />
                        
                        <div className={classes.dialogButton}>
                            <SubmitButton
                                variant="text"
                                color="secondary"
                                onClick={onCancel}
                            >
                                {!readOnly ? 'Cancel' : 'Back'}
                            </SubmitButton>
                            {
                                !readOnly && (
                                    <SubmitButton
                                        variant="text"
                                        color="primary"
                                        onClick={onSave}
                                    >
                                        Save
                                    </SubmitButton>
                                )
                            }
                        </div>
                    </FieldProvider>
                </DialogContent>
            </Dialog>
        </>
    );
}

const TissueSampleSubmission: React.FunctionComponent<ITissueSampleSubmissionProps> = () => {

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const [index, setIndex] = React.useState<number | undefined>(undefined);

    const [openSampleDialog, setOpenSampleDialog] = React.useState<boolean>(false);

    const sampleContext: ISampleContext = React.useMemo(() => {
        return {
            index,
            setIndex: (i) => setIndex(i),
            openSampleDialog: openSampleDialog,
            setOpenSampleDialog: (open) => setOpenSampleDialog(open),
        }
    }, [index, setIndex, openSampleDialog, setOpenSampleDialog]);

    const [prosignaStatus, setProsignaStatus] = React.useState<number | undefined>(undefined);

    const [prosignaStatusLoading, setProsignaStatusLoading] = React.useState<boolean>(false);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const { enqueueSnackbar } = useSnackbar();

    const setProsignaResultStatus = React.useCallback(() => {
        if (patient?.id != null) {
            setProsignaStatusLoading(true);

            client
                .get(new Dtos.GetProsignaAssayResultStatus({
                    patientId: patient?.id
                }))
                .then(async response => {
                    setProsignaStatus(response.status);

                    setProsignaStatusLoading(false);
                })
                .catch(() => {
                    setProsignaStatusLoading(false);
                })
        }
    }, [client, setProsignaStatus, patient]);

    const updateProsignaResultStatus = React.useCallback((status?: number) => {
        if (patient?.id != null) {
            setProsignaStatusLoading(true);

            client
                .post(new Dtos.SetProsignaAssayResultStatus({
                    patientId: patient?.id,
                    status: status
                }))
                .then(async _response => {
                    setProsignaStatus(status);

                    setProsignaStatusLoading(false);

                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                No More Tissue Available Confirmed
                            </AlertTitle>
                            No more tissue available has been confirmed.
                        </>,
                        { variant: 'success' }
                    );
                })
                .catch((e) => {
                    setProsignaStatusLoading(false);

                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Error
                            </AlertTitle>
                            An error occurred while attempting to make the update.
                        </>,
                        { variant: 'critical' }
                    );
                })
        }
    }, [client, enqueueSnackbar, patient, setProsignaStatus, setProsignaStatusLoading])

    React.useEffect(() => {
        setProsignaResultStatus();

    }, [setProsignaResultStatus])

    const afterFormSave = React.useCallback((_institution?: Dtos.IInstitution | null, _patient?: Dtos.IPatient | null, _event?: Dtos.IEvent | null, _form?: Dtos.TissueSampleSubmission | null) => {
        setProsignaResultStatus();
    }, [setProsignaResultStatus]);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.TissueSampleSubmission}
                validateOn="onChange"
                canEdit={canUpdateCrf}
                hideButtons
                afterFormSave={afterFormSave}
            >
                <SampleContext.Provider value={sampleContext}>
                    <TissueSampleGrid
                        prosignaAssayResultStatus={prosignaStatus}
                        updateProsignaResultStatus={updateProsignaResultStatus}
                        prosignaAssayResultStatusLoading={prosignaStatusLoading}
                    />
                </SampleContext.Provider>
                
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default TissueSampleSubmission;
